.scrollDownButton {
  background-color: var(--neutral0) !important;
  box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.5);
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  padding: 0 !important;
  color: black;
  min-width: 0px !important;
  text-transform: none;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  right: 20px;
  bottom: 64px;

  .badge {
    position: absolute !important;
    bottom: 56%;
    left: 56%;
    background-color: var(--secondary300);
    color: var(--neutral800);
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  svg {
    font-size: 28px;
    color: var(--neutral800);
  }
}
