.textBlock {
  margin-top: 8px;
  margin-bottom: 8px;

  &.noWrap {
    white-space: no-wrap;
    text-overflow: ellipsis;
  }

  &.maxLines {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
}

.choiceSet {
  width: 300px;

  label {
    padding: 12px 4px;
    border-bottom: 1px solid var(--neutral300);
    display: flex;
    font-weight: 600;

    @media screen and (max-height: 667px) {
      padding: 8px 4px;
    }
  }

  @media screen and (max-width: 391px) {
    width: 280px;
  }

  @media screen and (max-width: 371px) {
    width: 220px;
  }

  &.isBotExpanded {
    width: 400px;

    @media screen and (max-width: 546px) {
      width: 280px;
    }
  
    @media screen and (max-width: 414px) {
      width: 180px;
    }
  }

  input[type='checkbox'] {
    margin-right: 8px;
    transform: translateY(-1px);
    min-width: 16px;
  }
}

.imageContainer {
  padding: 10px 28px;
  border-radius: 50px;
  background: var(--neutral100);
  margin-right: 16px;
  width: 40px;

  .image {
    margin-left: -20px;
    margin-top: 8px;
    width: 40px !important;
  }
}

.columnSet {
  display: flex;
  align-items: center;
  box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.08);
  border-radius: 16px;
  padding: 12px;
  margin-bottom: 12px;

  &.pointer {
    cursor: pointer;
  }

  .hidden {
    visibility: hidden;
    min-width: 0 !important;
    padding: 0;
  }
}

.button {
  text-transform: none;
  margin: 20px 4px 12px 4px;
  background: var(--secondary200) !important;
  color: var(--neutral800) !important;
  border-radius: 16px !important;
  height: auto !important;
  padding: 2px 16px !important;
  font-size: 16px !important;

  &:hover,
  &:active {
    background: var(--secondary300) !important;
  }
  &:disabled {
    background-color: var(--neutral200) !important;
  }

  & > div {
    white-space: normal;
  }
}

.media {
  position: relative;
  display: flex;

  &.empty {
    min-height: 150px;
  }

  .videoPlayer {
    width: 100%;
  }

  .poster {
    width: 100%;
    height: 80%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
  }

  .play {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 16px;

    .playButton {
      padding: 0 16px;
      min-width: 0;
      color: var(--neutral800);
      background: transparent;
      font-weight: 600;
      font-size: 12px;

      &:hover,
      &:active {
        background: transparent;
      }

      svg {
        margin-left: 8px;

        &:hover,
        &:active {
          color: var(--secondary800);
        }
      }
    }
  }
}

.fileInput {
  display: flex;
  width: 100%;

  button {
    padding: 0 36px;
    min-width: unset !important;
    margin-right: 16px !important;
    text-transform: uppercase !important;
  }

  small {
    max-width: 200px;
  }

  .fileName {
    display: flex;
    align-items: center;
    background-color: var(--neutral200);
    padding: 8px;
    border-radius: 8px;

    & > svg {
      margin-right: 8px;
      background-color: var(--neutral0);
      padding: 4px;
      font-size: 32px;
      border-radius: 4px;
    }

    button {
      margin-right: 0 !important;
      margin-left: 8px;
    }
  }

  .confirm {
    margin-top: 12px;
  }

  .error {
    color: var(--error600);
    font-weight: 600;
  }
}
