@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

.botContainer {
  width: 450px;
  height: 100%;
  background-color: var(--bot-background);

  * > .material-icons {
    font-family: 'Material Icons' !important;
  }

  * > :not(.material-icons) {
    font-family: 'Nunito', sans-serif !important;
  }

  * > button {
    text-transform: none !important;
    & > div {
      white-space: normal !important;
    }
  }

  * > code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace !important;
    background: var(--neutral300);
    padding: 2px 4px;
    border-radius: 2px;
  }

  * > strong > * {
    font-weight: bold !important;
  }

  * > ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }

  * > ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  * > ::-webkit-scrollbar-thumb {
    border-radius: 16px;
    background-color: #808080;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    right: 0;
    height: 100%;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(3.5);
    height: 100%;
  }

  form {
    padding: unset !important;
  }

  .accessibleText {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .header {
    .banner {
      display: flex;
      align-items: center;
      background: var(--banner-background);
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-bottom: 1px solid var(--neutral200);

      @media screen and (max-width: 768px) {
        border-radius: 0;
      }

      .logo {
        background: var(--logo);
        background-repeat: no-repeat;
        background-size: cover;
        width: var(--logo-width);
        height: var(--logo-height);
        margin: var(--logo-margin);
      }

      .title {
        font-size: 20px;
        font-weight: 400;
        margin-left: 4px;
        padding-left: 12px;
        margin-top: 5px;
        border-left: 2px solid var(--neutral700);
        color: var(--title);

        &.isWhiteLabel {
          border-left: none;
          padding-left: 0;
        }
      }

      .controls {
        position: absolute;
        right: 12px;
        display: flex;

        button {
          margin-left: 4px;
        }

        @media screen and (max-width: 768px) {
          .expandIcon {
            display: none;
          }
        }
      }
    }

    .progress {
      padding: 12px 12px 0px 12px;
      height: 24px;
      display: var(--progressbar-display);

      .progressBar {
        height: 4px;
        background: var(--progressbar-background);
      }

      .progressValue {
        height: 4px;
        background: var(--progressbar-value);
        position: relative;
        bottom: 4px;
      }

      .percentage {
        position: relative;
        bottom: 18px;
        background: var(--bot-background);
        color: var(--progressbar-value);
        font-size: 16px;
        width: fit-content;
        padding: 0 6px;
      }
    }
  }

  .interviewIntroduction {
    display: none;
  }

  .scrollContainer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 580px;

    .chatContainer {
      margin-bottom: 12px;
      overflow-x: hidden;
    }

    @media screen and (max-width: 768px) {
      width: stretch;
      height: calc(100% - 160px);

      .chatBubble {
        max-width: 500px !important;
      }
    }

    &.isInputOpen {
      margin-bottom: 72px;
    }

    &.isAutoCompleteOpen {
      margin-bottom: 32px;
    }

    .chatBubbleContainer {
      display: flex;
      padding-top: 20px;

      &.fromBot {
        justify-content: start;
        display: none;
        width: 100%;
        flex-direction: column;

        .link {
          text-transform: none;

          .openInNewIcon {
            background: url('@resources/openInNew.svg');
            width: 16px;
            height: 16px;
            margin-left: 4px;
            background-size: cover;
          }
        }

        &.hasContent {
          display: flex;
        }
      }

      &.fromUser {
        align-items: flex-end;
        width: 100%;
        flex-direction: column;
      }

      .chatBubble {
        max-width: 436px;
        display: flex;

        &.fromBot {
          &::before {
            content: var(--chat-avatar);
            margin-left: 16px;
            margin-top: 8px;
            width: 36px;
            height: 36px;
            transform: scale(1.25);
          }
        }

        &.hasFeedbackAttachment {
          margin-bottom: 28px;
        }

        @media screen and (max-width: 320px) {
          max-width: 268px;
        }

        @media screen and (max-width: 376px) and (min-width: 321px) {
          max-width: 324px;
        }

        .chatBubbleItem {
          padding: 12px 20px;
          width: fit-content;
          color: white;
          font-size: 16px;
          text-align: left;
          overflow-wrap: anywhere;

          & > p {
            margin: 0;
          }

          blockquote {
            margin: 0;
          }

          & > span {
            word-break: break-word;
            p {
              margin: 0;
            }

            code {
              white-space: wrap;
            }
          }

          img {
            width: 100%;
          }

          &.fromUser {
            background: var(--neutral200);
            color: var(--neutral800);
            border-radius: 8px;
            flex-direction: row-reverse;
            margin: 4px 8px;
          }

          &.fromBot {
            background: var(--neutral0);
            color: var(--neutral800);
            border-radius: 8px;
            flex-direction: row;
            margin: 4px 8px;
            max-width: 356px;

            p {
              word-break: break-word;
            }

            a {
              word-break: break-word;
              text-transform: none;
              font-size: 16px;
            }
          }
        }
      }
      .attachment {
        .adaptiveCard {
          &.expanded {
            embed {
              min-width: 600px;
              min-height: 360px;
            }
          }
          .feedback {
            right: -40px;
            top: 32px;
          }
        }
      }
    }

    .timestamp {
      display: flex;
      margin-left: 60px;
      font-size: 12px;

      .retryLink {
        background: none;
        color: var(--secondary700);
        text-transform: none;
        padding: 0;
        margin: 0;
        height: 0;
        min-width: 0;
        font-size: 12px;

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }

      &.fromUser {
        float: right;
        margin-right: 12px;
      }
    }
  }

  .input {
    width: stretch;
    width: -moz-available;
    margin: 12px 16px 12px 16px;
    align-items: stretch;
    position: absolute;
    bottom: 0;
    height: 46px;

    .typingIndicator {
      position: absolute;
      bottom: 48px;
      left: -24px;
    }

    .errorIndicator {
      position: relative;
      bottom: 0;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      svg {
        color: var(--error700);
        font-size: 16px;
        margin-right: 4px;
      }
    }

    textarea {
      background-color: white;
      border: none;
      min-height: 0;
      height: 40px;
      padding: 8px 36px 8px 16px;
      box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.08);

      &.isPdfDownloadEnabled {
        padding: 8px 36px 8px 44px;
      }

      &::placeholder {
        font-family: 'Nunito', sans-serif !important;
        color: var(--neutral800);
        font-size: 15px;
      }

      &:focus,
      &:active {
        outline: 1px solid var(--secondary500);
      }
    }

    .saveButton {
      position: absolute;
      bottom: 10px;
      left: 4px;
      min-width: 36px;
      padding: 0;
      background: transparent !important;
    }

    .sendButton {
      position: absolute;
      bottom: 6px;
      right: 0px;
      min-width: 44px;
      padding: 0;
      background: transparent !important;
      border-radius: 8px !important;

      &:hover,
      &:active {
        background: var(--neutral200) !important;
      }

      svg {
        color: var(--neutral800);
      }
    }
  }

  .suggestedActions {
    .dropdownContainer {
      position: relative;

      .dropdown {
        position: absolute;
        width: stretch;
        width: -moz-available;
        margin: 0 24px 0 60px;

        button {
          margin-top: 12px !important;
        }

        input,
        option,
        div[role='option'] {
          font-size: 16px;
        }
      }
    }

    .buttonContainer {
      margin: 12px;
      width: 100%;
      margin-right: 16px;

      button {
        width: 80%;
        background-color: var(--neutral300);
        border-radius: 4px !important;
        min-height: 40px !important;
        margin: 12px 0px 12px 48px !important;
        font-weight: 700 !important;
        text-transform: uppercase !important;

        &:hover {
          background-color: var(--neutral400) !important;
        }
      }

      &.isBotExpanded {
        button {
          margin: 12px 0px !important;
        }
      }
    }

    &.adeccofull {
      display: flex;
      justify-content: start;
      margin-left: 56px;
    }

    @media screen and (max-width: 768px) {
      justify-content: center;
    }

    button {
      margin: 4px !important;
      background: var(--secondary200);
      color: var(--neutral800) !important;
      text-transform: none;
      border-radius: 16px !important;
      height: auto;
      padding: 2px 16px;

      &:hover,
      &:active {
        background: var(--secondary300) !important;
      }
      &:disabled {
        background-color: var(--neutral200) !important;
      }

      & > div {
        white-space: normal !important;
      }
    }
  }

  &.isExpanded {
    width: 100%;
    height: 100%;

    .chatBubbleContainer {
      &.fromBot,
      &.fromUser {
        width: 80%;
        margin: 0 10%;

        @media screen and (min-width: 1441px) {
          width: auto;
          margin: 0px 28%;
        }
      }

      .suggestedActions {
        display: flex;
        justify-content: start;
        margin-left: 0;
      }

      .chatBubble {
        max-width: 700px !important;

        .chatBubbleItem {
          max-width: 700px !important;
        }
      }
    }

    .input {
      width: 80%;
      margin: 12px 10%;

      @media screen and (min-width: 1441px) {
        width: 43%;
        margin: 12px 28%;
      }
    }
  }

  &.adeccofull {
    .interviewIntroduction {
      display: flex;
      padding: 12px 28px;
      margin: 16px 8px;
      align-items: center;

      button {
        position: absolute;
        top: 96px;
        right: 12px;
        margin-right: 4px;
      }

      .text {
        margin-right: 8px;
      }
    }

    .header {
      .controls {
        display: none;
      }
    }

    @media screen and (min-width: 768px) {
      width: 100%;
      height: 100vh;
      position: static;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .header {
        width: 100%;

        .banner {
          border-radius: 0;
        }

        .progress {
          display: none;
        }
      }

      .scrollContainer {
        width: stretch;
        width: -moz-available;
        height: calc(100% - 160px);

        .chatBubble {
          max-width: 500px !important;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      .scrollContainer {
        width: 50%;

        .chatBubble {
          max-width: 500px !important;

          .chatBubbleItem {
            &.fromBot,
            &.fromUser {
              max-width: 500px;
            }
          }
        }
      }

      .interviewIntroduction {
        width: 50%;

        button {
          display: none;
        }
      }

      .input,
      .buttonBar {
        width: 50% !important;
      }
    }

    @media screen and (min-width: 1441px) {
      .scrollContainer {
        .chatContainer {
          .chatBubbleContainer {
            &.fromBot,
            &.fromUser {
              margin: 0 10%;
            }
          }
        }
      }

      .input {
        margin: 12px 0 !important;
      }
    }
  }
}
