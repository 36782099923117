.feedback {
  float: right;
  display: flex;
  justify-content: end;
  align-items: end;

  .negativefeedbackCard {
    box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.5);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 52px;
    float: right;
  }

  button {
    text-transform: none;
    margin: 4px;
    background: var(--secondary200);
    color: var(--neutral800);
    border-radius: 16px;
    height: auto;
    padding: 2px 16px;

    &:hover,
    &:active {
      background: var(--secondary300);
    }
    &:disabled {
      background-color: var(--neutral200);
    }

    & > div {
      white-space: normal;
    }
  }

  .buttonBar {
    display: flex;
    justify-content: flex-end;
    background: transparent;
    button {
      background: none !important;
      color: var(--neutral800);
      border-radius: 8px !important;
      min-width: 32px;
      margin: 4px;
      font-size: 32px;
      padding: 8px;

      &:hover,
      &:active {
        border: 2px solid var(--secondary400);
        padding: 6px;
      }
    }
  }
}

.negativefeedbackContainer {
  position: relative;
  display: flex;
  justify-content: center;

  .feebackForm {
    box-shadow: 0px -8px 20px 0px rgba(28, 48, 75, 0.1);
    position: absolute;
    border-radius: 8px;
    padding: 16px;
    z-index: 10;
    width: 400px;
    bottom: -24px;
    left: 36px;

    &.isBotExpanded {
      bottom: 8px;
      right: -12px;
      left: unset;
    }

    button {
      background-color: var(--secondary200);
      color: var(--neutral800);
      padding: 4px;
      margin: 8px 0;
      height: 28px;
      border-radius: 12px;
    }

    .closeIcon {
      background-color: transparent;
      float: right;
      position: relative;
      top: -12px;
      right: -12px;
      border-radius: 8px;
    }
    .hint {
      font-size: 12px;
      margin: 0;
    }

    .buttonContainer {
      display: flex;
      flex-wrap: wrap;
      margin: 8px 0;

      .secondary {
        background: var(--neutral0) !important;
        border: 1px solid var(--neutral600);
        color: var(--neutral700);
        margin: 4px;
        padding: 4px;
        height: 24px;
        border-radius: 12px;

        &:hover,
        &:active {
          background: var(--neutral200) !important;
        }
      }
    }
  }
}

.separator {
  margin-top: 16px;
}
