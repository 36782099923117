.media {
  position: relative;
  display: flex;

  &.empty {
    min-height: 150px;
  }

  .poster {
    width: 100%;
    height: 80%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
  }

  .play {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 16px;

    .playButton {
      padding: 0 16px;
      min-width: 0;
      color: var(--neutral800);
      background: transparent;
      font-weight: 600;
      font-size: 12px;

      &:hover,
      &:active {
        background: transparent;
      }

      svg {
        margin-left: 8px;

        &:hover,
        &:active {
          color: var(--secondary800);
        }
      }
    }
  }
}

.fileInput {
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    padding: 0 36px;
    min-width: unset !important;
    margin-right: 16px !important;
    text-transform: uppercase !important;
  }

  small {
    max-width: 200px;
  }

  .fileName {
    display: flex;
    align-items: center;
    background-color: var(--neutral200);
    padding: 8px;
    border-radius: 8px;

    & > svg {
      margin-right: 8px;
      background-color: var(--neutral0);
      padding: 4px;
      font-size: 32px;
      border-radius: 4px;
    }

    button {
      margin-right: 0 !important;
      margin-left: 8px;
    }
  }

  .confirm {
    margin-top: 12px;
  }

  .error {
    color: var(--error600);
    font-weight: 600;
  }
}
