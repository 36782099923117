.searchBar {
  display: flex;
  position: relative;

  & > svg {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: default;
  }

  & > input {
    background-color: var(--neutral100);
    border: none;
    border-radius: 8px;
    min-height: 0;
    height: 56px;
    padding: 16px 16px 16px 44px;
    width: 100%;
    font-size: 16px !important;

    &::placeholder {
      color: var(--neutral800);
      font-size: 16px;
    }

    &:focus,
    &:active {
      outline: 1px solid var(--secondary500);
    }
  }

  .clearButton {
    position: absolute !important;
    right: 0;
    top: 12px;
  }

  .loader {
    position: absolute !important;
    right: 8px;
    top: 20px;
    background: var(--neutral0);
    cursor: default;
  }
}

.botExpanded {
  & > svg {
    top: 8px;
  }

  & > input {
    height: 40px;
    padding: 8px 16px 8px 44px;
  }
}
