.accordion {
  box-shadow: 4px 2px 16px 0px #1c304b14;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--neutral0);
    border-bottom: 1px solid var(--neutral200);
    width: 100%;
    padding: 16px 24px;
    height: auto;
    border-radius: 8px;
    gap: 16px;
    cursor: pointer;
    font-size: 14px;
    color: var(--primary500);
    font-size: 16px;
    word-break: break-word;
    text-align: left;

    &:hover,
    &:active {
      background-color: var(--neutral200);
    }
  }

  .content {
    padding: 16px 24px;
    background: var(--neutral100);
    word-break: break-word;
  }

  &.open {
    .header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }

    .content {
      border-top: 1px solid var(--neutral300);
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
