.style-button,
.ac-pushButton {
  text-transform: none !important;
  margin: 4px !important;
  background: var(--secondary200) !important;
  color: var(--neutral800) !important;
  border-radius: 16px !important;
  height: auto !important;
  padding: 2px 16px !important;

  &:hover,
  &:active {
    background: var(--secondary300) !important;
  }
  &:disabled {
    background-color: var(--neutral200) !important;
  }

  & > div {
    white-space: normal !important;
  }
}

.ac-adaptiveCard {
  padding: 0 !important;

  * {
    font-size: 16px;
  }

  .ac-actionSet {
    flex-wrap: wrap;

    & > div {
      display: none;
    }
  }

  .ac-input-container input + span.input-icon-box {
    font-family: 'Material Icons' !important;
  }

  .ac-columnSet {
    box-shadow: 4px 8px 40px rgba(28, 48, 75, 0.08);
    border-radius: 16px;
    padding: 12px !important;
    margin: 2px -8px !important;

    &.ac-selectable {
      cursor: pointer;
    }

    .ac-image-container {
      padding: 6px 28px;
      border-radius: 50px;
      background: var(--neutral100);
      margin-right: 16px;

      .ac-image {
        margin: 12px -20px 8px -20px;
      }
    }

    .ac-textBlock {
      margin: 4px 0px;
    }
  }
}

.ac-textBlock {
  font-size: 16px !important;
  white-space: normal !important;
}

.ac-choiceSetInput-multiSelect {
  & > div {
    padding: 3px 0;
    align-items: baseline !important;

    & > label {
      white-space: normal !important;
      align-items: center;
      transform: translateY(-3px);
    }
  }
}

.ac-horizontal-separator {
  height: 16px !important;
}

.ac-media {
  box-sizing: border-box;
  flex: 0 0 auto;
  min-width: 320px;
}
