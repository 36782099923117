.typingIndicator {
  display: inline-block;
  transform: scale(0.75);

  .dots {
    display: inline-block;
    div {
      animation: loadingSmall 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 100%;
      background: #273446;
      margin: 0 3.5px;
    }
  
    .indicatorDotOne {
      animation-delay: -0.6s;
      margin-left: 8px;
    }
  
    .indicatorDotTwo {
      animation-delay: -0.3s;
    }
  
    .indicatorDotThree {
      animation-delay: -0.15s;
    }
  }
}

.dots {
  display: inline-block;
  div {
    animation: loadingSmall 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    background: #273446;
    margin: 0 3.5px;
  }

  .indicatorDotOne {
    animation-delay: -0.6s;
    margin-left: 8px;
  }

  .indicatorDotTwo {
    animation-delay: -0.3s;
  }

  .indicatorDotThree {
    animation-delay: -0.15s;
  }
}

@keyframes loadingSmall {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -100%);
  }

  100% {
    transform: translate(0, 0);
  }
}
