.loader {
  display: flex;
  align-items: center;
  justify-content: center;

  &.fullheight {
    height: 100vh;
  }

  .wrapper {
    height: 16px;
    position: relative;
    width: 16px;

    .spinner {
      &::before {
        border: 2px solid var(--neutral200);
        border-radius: 50%;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
      }

      &::after {
        animation: spin 1s linear infinite;
        border-bottom: 2px solid #0000;
        border-left-color: #0000;
        border-left: 2px solid var(--secondary400);
        border-radius: 50%;
        border-right: 2px solid #0000;
        border-top-color: #0000;
        border-top: 2px solid var(--secondary400);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }

    .title {
      margin-left: 28px;
      width: 100%;
      text-wrap: nowrap;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
