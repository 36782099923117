.calendar {
  box-shadow: 4px 2px 16px 0px #1c304b14;
  min-width: 364px;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--neutral0);

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .monthLabel {
      display: flex;
      align-items: center;
      background-color: var(--neutral0);
      border: none;
      padding: 4px 8px 4px 12px;
      font-size: 16px;
      border-radius: 6px;

      &:hover,
      &:active {
        border: 2px solid var(--secondary400);
        padding: 2px 6px 2px 10px;
      }
    }

    button {
      background-color: var(--neutral0);
      border-radius: 4px;
      border: none;
      cursor: pointer;
      padding: 8px 4px;
      color: var(--neutral700);
      min-width: 24px;
      height: 28px;

      &:hover,
      &:active {
        border: 2px solid var(--secondary400);
        padding: 6px 2px;
      }
    }
  }

  .yearPicker {
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(4, 76px);
    height: 264px;
    text-align: center;
    gap: 12px;

    button {
      background-color: var(--neutral0);
      border-radius: 16px;
      border: none;
      cursor: pointer;
      min-width: 76px;
      padding: 0;
      font-size: 14px;
      color: var(--neutral700);

      &:hover,
      &:active {
        border: 1px solid var(--secondary400);
      }
    }
  }

  .month {
    display: grid;
    grid-template-columns: repeat(7, 48px);

    .week {
      font-size: 12px;
      display: flex;
      justify-content: space-around;
      margin-bottom: 12px;
      color: var(--neutral700);
    }

    .day {
      button {
        background-color: var(--neutral0);
        border: none;
        border-radius: 50px;
        padding: 0;
        min-width: 40px;
        font-size: 16px;
        color: var(--neutral700);

        .today {
          display: flex;
          justify-content: center;
          position: relative;

          &::after {
            content: '';
            background: var(--secondary400);
            width: 6px;
            height: 6px;
            display: block;
            border-radius: 50%;
            position: absolute;
            right: 2px;
            bottom: -16px;
          }
        }

        &:disabled {
          color: var(--neutral500);
          cursor: not-allowed;
        }

        &:hover:not(:disabled),
        &:active:not(:disabled) {
          border: 1px solid var(--secondary400);
          cursor: pointer;
        }

        &.selected {
          background-color: var(--secondary200);
          border-radius: 50%;

          &:hover:not(:disabled),
          &:active:not(:disabled) {
            background-color: var(--secondary400);
            color: var(--neutral0);
          }
        }
      }
    }
  }
}
