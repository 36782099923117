.input {
  input:disabled {
    &::after {
      background-color: var(--neutral800) !important;
    }
  }
  .error {
    font-size: 12px;
    color: var(--error700);
  }
}
