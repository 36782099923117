.input {
  label {
    textarea {
      margin-top: 8px;
      min-height: 56px;
      overflow: hidden;
      font-size: 16px !important;

      &:disabled {
        background: var(--neutral200);
        border: 1px solid var(--neutral800);
        color: var(--neutral600);
      }
    }
  }

  .error {
    padding-top: 8px;
    color: var(--error700);
    font-size: 12px;
  }
}
