.datepicker {
  position: relative;

  .inputWrapper {
    border: 1px solid var(--primary500);
    border-radius: 8px;
    display: flex;
    padding: 8px 8px;

    input {
      width: 100%;
      outline: none;
      border: none;
      padding: 0 8px;
      font-size: 16px;

      &:disabled {
        border: none !important;
      }

      &:active {
        outline: none;
        border: none;
      }
    }

    &.error {
      border: 1px solid var(--error600);
    }
  }

  .error {
    padding-top: 8px;
    color: var(--error600);
    font-size: 12px;
  }

  .calendarWrapper {
    position: absolute;
    left: -20px;
    z-index: 1000;
  }
}
