.notification {
  min-height: 80px;
  box-shadow: 4px 2px 16px 0px #1c304b14;
  border-radius: 8px;
  display: flex;
  margin: 12px;

  @media screen and (min-width: 1441px) {
    width: auto;
    margin: 12px 28%;
  }

  .accent {
    width: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    border-radius: 8px 0px 0px 8px;

    &.success {
      background: var(--success400);
    }

    &.error {
      background: var(--error400);
    }

    &.info {
      background: var(--secondary300);
    }

    &.warning {
      background: var(--alert400);
    }
  }

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px;
    width: 100%;
  }

  .header {
    font-weight: 600;
  }
}
