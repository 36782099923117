.wrapper {
  &.expanded {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}

.autoComplete {
  position: relative !important;
  padding: 0 4px;
  margin: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.expanded {
      width: 684px;
    }

    & > button {
      width: 100%;
      text-transform: none;
      margin: 12px 4px;
      background: var(--secondary200);
      color: var(--neutral800);
      border-radius: 16px;
      height: auto;
      padding: 2px 16px;

      &:hover,
      &:active {
        background: var(--secondary300);
      }
      &:disabled {
        background-color: var(--neutral200);
      }

      & > div {
        white-space: normal;
      }

      &.secondary {
        background: var(--neutral0);
        border: 1px solid var(--neutral600);

        &:hover,
        &:active {
          background: var(--neutral200);
        }
      }

      /* hack for Safari browser */
      @media not all and (min-resolution: 0.001dpcm) {
        width: stretch;
      }

      /* hack for iOS browsers */
      @supports (-webkit-touch-callout: none) {
        width: stretch;
      }
    }
  }

  .selectedItems {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 8px;
    margin-top: 12px;
    border-radius: 8px;
    background: var(--neutral100);

    & > div {
      text-transform: none;
      background: var(--secondary200);
      margin: 4px;
      pointer-events: all;

      &:hover,
      &:active {
        background: var(--secondary300);
      }

      & > span {
        font-size: 14px;
      }
    }
  }

  .loaderContainer {
    height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    .searchBox {
      display: flex;
      margin: 12px 4px;
      align-items: center;

      & > div {
        width: stretch;
        width: -moz-available;
      }

      .sendButton {
        bottom: 8px;
        right: 0px;
        min-width: 40%;
        padding: 0 12px;
        margin-left: 8px;
        white-space: normal;
        line-height: 1;
        border-radius: 8px !important;
      }

      .closeButton {
        margin-left: 8px;
        min-width: 40%;
        padding: 0 12px;
        white-space: normal;
        line-height: 1;
      }
    }

    .list {
      padding: 12px;
      overflow-y: auto;
      max-height: 280px;

      .numberInput {
        max-width: 280px;
        margin: 8px 0 12px 24px;
      }

      &.autoCompleteWithCategories {
        & > div {
          margin: 8px 0;
          box-shadow: none !important;

          & > div {
            background: var(--neutral100);
            font-size: 16px;

            & > span {
              font-family: 'Material Icons' !important;
            }
          }
        }

        label {
          font-size: 16px;
          display: flex;
          align-items: center;
          margin: 6px 0;
        }
      }
    }

    .buttonContainer {
      margin: 12px 8px;

      & > button {
        text-transform: none;
        margin: 12px 16px 12px 0;
        background: var(--secondary200);
        color: var(--neutral800);
        border-radius: 16px;
        height: auto;
        padding: 2px 16px;

        &:hover,
        &:active {
          background: var(--secondary300);
        }
        &:disabled {
          background-color: var(--neutral200);
        }

        & > div {
          white-space: normal;
        }

        &.secondary {
          background: var(--neutral0);
          border: 1px solid var(--neutral600);

          &:hover,
          &:active {
            background: var(--neutral200);
          }
        }

        /* hack for Safari browser */
        @media not all and (min-resolution: 0.001dpcm) {
          width: stretch;
          width: -moz-available;
          margin-right: 32px;
        }

        /* hack for iOS browsers */
        @supports (-webkit-touch-callout: none) {
          width: stretch;
          width: -moz-available;
          margin-right: 32px;
        }
      }
    }
  }

  .confirmation {
    padding: 8px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .titleText {
        font-size: 20px;
      }
    }

    .chipContainer {
      .chip {
        border: 1px solid var(--neutral700);
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding-left: 8px;
        font-size: 14px;
        justify-content: space-between;
        margin: 4px 0;
      }
    }

    .buttonContainer {
      &.isExpanded {
        width: 684px;
        margin-left: 10%;
      }

      & > button {
        width: stretch;
        width: -moz-available;
        text-transform: none;
        margin: 12px 0;
        background: var(--secondary200);
        color: var(--neutral800);
        border-radius: 16px;
        height: auto;
        padding: 2px 16px;

        &:hover,
        &:active {
          background: var(--secondary300);
        }
        &:disabled {
          background-color: var(--neutral200);
        }

        & > div {
          white-space: normal;
        }

        &.secondary {
          background: var(--neutral0);
          border: 1px solid var(--neutral600);

          &:hover,
          &:active {
            background: var(--neutral200);
          }
        }

        /* hack for Safari browser */
        @media not all and (min-resolution: 0.001dpcm) {
          width: stretch;
          width: -moz-available;
          margin-right: 32px;
        }

        /* hack for iOS browsers */
        @supports (-webkit-touch-callout: none) {
          width: stretch;
          width: -moz-available;
          margin-right: 32px;
        }
      }
    }
  }

  &.expanded {
    width: 800px;
    display: flex;
    justify-content: space-around;
  }
}
