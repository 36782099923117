.notificationTitle {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;

  .thumbText {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .titleText {
      font-family: var(--fontFamily) !important;
      text-transform: none;
      font-weight: 400;
      font-size: 16px;
      color: var(--neutral800);
    }

    .titleText:hover {
      text-decoration: underline;
    }
  }

  .dropdownIcon {
    color: var(--neutral800) !important;
  }
}

.nps {
  margin: 16px 12px 12px 12px;

  &.expanded {
    width: 74%;
    margin-left: 15%;

    @media screen and (max-width: 460px) {
      margin-left: 5%;
      width: 91%;
    }

    @media screen and (min-width: 1441px) {
      margin: 24px 28%;
      width: auto;
      max-width: 700px;
    }
  }

  & > div {
    padding: 8px 16px;

    @media screen and (max-width: 420px) {
      padding: 8px;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .feedbackMessage {
    margin-bottom: 8px;
  }

  .container {
    .buttonBar {
      display: flex;
      justify-content: space-between;

      button {
        background: transparent;
        border: 1px solid var(--secondary700);
        border-radius: 4px;
        margin: 0 1px;
        min-width: calc(3 * var(--unit));
        max-width: calc(4 * var(--unit));
        padding: 0;
        color: var(--secondary700);
        height: 32px;
        position: relative;
        font-size: 16px;

        @media screen and (max-width: 425px) {
          max-width: calc(3.5 * var(--unit));
        }

        @media screen and (min-width: 688px) and (max-width: 768px) {
          min-width: calc(5 * var(--unit));
          margin: 0 3px;
        }

        &:hover,
        &:active {
          padding: 0 !important;
        }

        &.expanded {
          @media screen and (max-width: 550px) {
            max-width: calc(3.5 * var(--unit));
          }
          @media screen and (min-width: 769px) and (max-width: 989px) {
            min-width: calc(4 * var(--unit));
          }
          @media screen and (min-width: 990px) {
            min-width: calc(5 * var(--unit));
          }
        }

        &.selected {
          border: 2px solid var(--secondary700);
          border-radius: 4px;

          @media screen and (max-width: 425px) {
            padding: 0;
            min-width: 24px;
          }

          .selectedIcon {
            background: url('~@resources/brands/Adecco/SliderDot.svg');
            height: 24px;
            width: 25px;
            transform: scale(0.75);
          }
        }
      }
    }

    .helper {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      color: var(--neutral700);
      margin-top: 8px;
      margin-bottom: 16px;
    }

    .submitButtons {
      display: flex;
      justify-content: center;
      gap: 16px;

      button {
        width: 100%;
        margin: 16px 0;
      }
    }

    & > button {
      width: 100%;
      margin: 16px 0;
    }
  }
}

.tooltip {
  position: absolute;
  background: var(--neutral800);
  color: var(--neutral0);
  border-radius: 8px;
  padding: 8px 12px;
  z-index: 100000000;
  display: flex;
  justify-content: center;
  top: 40px;
  width: 40px;
  font-size: 20px;

  &::before {
    content: '';
    position: absolute;
    top: -20%;
    left: 12px;
    margin-left: 0px;
    width: 0;
    height: 0;
    border-bottom: solid 8px var(--neutral800);
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }
}

.loaderCard {
  display: grid;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  margin: 24px auto;
  padding: 60px 0;

  & > h4 {
    font-size: 16px;
    font-weight: 600;
    margin: 12px auto;
    text-align: center;
  }

  & > div {
    width: 45px;
    margin: 0 auto;
  }
}
