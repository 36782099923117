.numberInput {
  .caption {
    font-size: var(--fontSizeSmall);
    font-weight: 400;
    line-height: var(--lineHeightSmall);
    text-transform: uppercase;
  }

  .inputWrapper {
    border: 1px solid var(--primary500);
    border-radius: 8px;
    display: flex;
    padding: 8px 8px;

    button {
      border-radius: 50%;
      padding: 0;
      border: none;
      background-color: var(--neutral300);
      color: var(--neutral700);
      display: flex;
      align-items: center;
      cursor: pointer;
      min-width: 24px;
      height: 24px;

      &:hover,
      &:active {
        background-color: var(--neutral300);
      }

      svg {
        font-size: 16px;
      }
    }

    input {
      width: 100%;
      outline: none;
      border: none;
      padding: 0 8px;
      text-align: center;
      font-size: 14px;

      &:active {
        font-size: 16px;
      }
    }
  }
}
